// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-flavors-jsx": () => import("./../../../src/pages/flavors.jsx" /* webpackChunkName: "component---src-pages-flavors-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-location-jsx": () => import("./../../../src/pages/location.jsx" /* webpackChunkName: "component---src-pages-location-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-order-jsx": () => import("./../../../src/pages/order.jsx" /* webpackChunkName: "component---src-pages-order-jsx" */)
}

